import React from "react";
import Launchscreen from "../pages/signupPages/Launchscreen";
import Otp from "../pages/signupPages/Otp";
// import Signup from '../pages/signupPages/Signup';
// import Homescreen from '../pages/signin pages/Homescreen';
// import Signinscreen from '../pages/signin pages/Signinscreen';
// import Scan from '../pages/scan-pages/Scan';
import Order from "../pages/scan-pages/Order";
// import OrderReady from '../pages/scan-pages/OrderReady';
// import OrderfeedBack from '../pages/scan-pages/OrderfeedBack';
// import AppfeedBack from '../pages/scan-pages/AppfeedBack';
import { Route, Routes } from "react-router-dom";
import RefrencePage from "../pages/signupPages/RefrencePage";
import QrCode from "../pages/scan-pages/QrCode";
import QrCodeMarketing from "../pages/scan-pages/QrCodeMarketing";
// import GameModel from '../pages/signupPages/GameModel';

// import WrongNumber from '../pages/signupPages/WrongNumber';
// import GameWordle from '../pages/signupPages/GameWordle';
import Setting from "../pages/Setting/Setting";
import QuestGame from "../pages/QuestGame";
import Loyalty from "../pages/Loyalty";
import LoyaltyTwo from "../pages/Setting/LoyaltyTwo";
import ScrollToTop from "../components/ScrollToTop";
import ProtectedRoute from "./ProtectedRoute";
import GetKnow from "../pages/GetKnow";
import Visiting from "../pages/signupPages/Visiting";
import GoogleReview from "../pages/signupPages/GoogleReview";
// import Unsupported from '../pages/Unsupported';
import LetsOrder from "../pages/LetsOrder";
import QrScanner from "../pages/QrScanner";
import Account from "../pages/Account";
import OrderHistry from "../pages/OrderHistry";
import LoginOrSignup from "../pages/LoginOrSignup";
import PhoneNumber from "../pages/PhoneNumber";
import OfferRewards from "../pages/OfferRewards";
import NearbyBusinesses from "../pages/NearbyBusinesses";
import ManageDetails from "../pages/ManageDetails";
import OrderHistryWithOutLogin from "../pages/OrderHistryWithOutLogin";
import OfferRewardCardDetail from "../pages/OfferRewardCardDetail";
import OfferDetail from "../pages/OfferDetail";
import RewardDetail from "../pages/RewardDetail";
import OtpNew from "../pages/OtpNew";
const AppRoute = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/:BK/:oId?" element={<Launchscreen />} />
        <Route path="/order/link" element={<Launchscreen />} />
        <Route path="/order/find" element={<RefrencePage />} />
        {/* <Route path="/otp" element={<Otp />} /> */}
        <Route path="/getKnow" element={<GetKnow />} />
        <Route path="/feedback/:bk/:oid" element={<Visiting />} />
        <Route path="/googleReview" element={<GoogleReview />} />
        <Route path="/letsOrder" element={<LetsOrder />} />
        <Route path="/" element={<LetsOrder />} />
        <Route path="/qrScanner" element={<QrScanner />} />
        <Route path="/account" element={<Account />} />
        <Route path="/orderHistry" element={<OrderHistry />} />
        <Route
          path="/orderHistry/withOut-login"
          element={<OrderHistryWithOutLogin />}
        />
        <Route path="/loginOrSignup" element={<LoginOrSignup />} />
        <Route path="/phoneNumber" element={<PhoneNumber />} />
        <Route path="/offerRewards" element={<OfferRewards />} />
        <Route
          path="/offerRewards/card/detail"
          element={<OfferRewardCardDetail />}
        />
        <Route path="/offer/detail" element={<OfferDetail />} />
        <Route path="/reward/detail" element={<RewardDetail />} />
        <Route path="/nearbyBusinesses" element={<NearbyBusinesses />} />
        <Route path="/manageDetails" element={<ManageDetails />} />
        <Route exact path="/" element={<ProtectedRoute />}>
          {/* <Route path="/orderReady" element={<OrderReady />} /> */}
          {/* <Route path="/wrongnumber" element={<WrongNumber />} /> */}
          {/* <Route path="/order/feedback" element={<OrderfeedBack />} /> */}
          {/* <Route path="/appfeedback" element={<AppfeedBack />} /> */}
          <Route path="/Qrcode/:cid?" element={<QrCode />} />
          {/* <Route path="/game" element={<GameModel />} /> */}
          {/* <Route path="/signup" element={<Signup />} /> */}
          {/* <Route path="/homescreen" element={<Homescreen />} /> */}
          {/* <Route path="/signin" element={<Signinscreen />} /> */}
          {/* <Route path="/scan" element={<Scan />} /> */}
          <Route path="/order" element={<Order />} />
          <Route path="/questGame" element={<QuestGame />} />
          <Route path="/offers/:bid?" element={<Loyalty />} />
          <Route path="/loyaltyTwo" element={<LoyaltyTwo />} />
          <Route path="/qrCodeMarketing" element={<QrCodeMarketing />} />
          <Route path="/setting" element={<Setting />} />
        </Route>

        {/* ========== 8/6/24 new  screen  */}
        <Route path="/otp" element={<OtpNew />} />
      </Routes>
    </>
  );
};

export default AppRoute;
