import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessOrderData,
  getUser,
  submitFeedback,
  submitSurvey,
  updateMarketingInteractionStatus,
  updateOrderStateFromSocket,
  UpdateOrderStatus,
  updateSurveyInteractionStatus,
} from "../../redux/slice/UserSlice";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import { encode } from "base-64";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import goggle from "../../assets/images/GoggleG.png";
import close from "../../assets/images/close.png";
import GoggleReview from "../../assets/images/GoggleReview.png";
import greentick from "../../assets/images/greentick.png";
import location from "../../assets/images/location.svg";
import loyalty from "../../assets/images/loyalty-img2.png";
import termsCondation from "../../assets/images/termsCondation.png";
import finalLogo from "../../assets/images/TimeLinkLogoBlackText.png";
import timer from "../../assets/images/timmer.svg";
import Stepper from "../../components/Steppers";
import { io } from "socket.io-client";
// const socket = io('http://localhost:4000', {
//   transports: ['websocket'],
// });

const style = {
  position: "fixed", // Change position to fixed
  bottom: 0, // Align modal to the bottom
  left: "50%",
  transform: "translateX(-50%)", // Center horizontally
  bgcolor: "background.paper",
  borderTopLeftRadius: "25px",
  borderTopRightRadius: "25px",
  maxHeight: "90vh", // Limit modal height to 90% of viewport height
  minHeight: "50vh",
  overflowY: "auto", // Add scroll when content overflows
};
const styleSurvey = {
  position: "fixed", // Change position to fixed
  border: "none",
  bottom: 0,
  width: "100%",
  left: "50%",
  transform: "translateX(-50% )", // Center horizontally
  bgcolor: "background.paper",
  borderTopLeftRadius: "25px",
  borderTopRightRadius: "25px",
  minHeight: "60vh", // Limit modal height to 90% of viewport height
  overflowY: "auto", // Add scroll when content overflows
  outline: "none",
};
const Order = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [randomSelecetedCampaing, setRandomSelecetedCampaing] = useState(0);
  const [hasCampaignDisplayed, setHasCampaignDispalyed] = useState(false);
  const [hasSurveyOpened, setHasSurveyOpened] = useState(false);
  const { orderDetail, userDetail, getError, isLoading } = useSelector(
    (state) => state.user
  );
  const [mblTerms, setMblTerms] = useState(true);
  const [remainingTime, setRemainingTime] = useState("");
  const [isLoadingOnPageLoad, setIsLoadingOnPageLoad] = useState(false);
  // --------Modal----------------
  const [campaignModal, setCampaignModal] = React.useState(false);
  const [campaignYesModal, setCampaignYesModal] = React.useState(false);
  const [openModalSurvey, setOpenModalSurvey] = React.useState(false);
  const [surveyDataModal, setSurveyDataModal] = React.useState(false);
  const [feedbackModal, setFeedbackModal] = React.useState(false);
  const [openModalGoggleReview, setOpenModalGoggleReview] =
    React.useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [googleReviewLink, setGoogleReviewLink] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [otherFieldValue, setOtherFieldValue] = useState("");
  const [otherFieldVisible, setOtherFieldVisible] = useState(false);
  const [isOrderPickedUpClicked, setIsOrderPickedUpClicked] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [anonymous, setAnonymous] = useState(null);
  const reactionEmojies = ["😠", "😟", "😐", "🙂", "😀"];
  const [screenLoading, setScreenLoading] = useState(true);
  // useEffect(() => {
  //   if (getError && getError.length > 1) {
  //     toast.error(getError, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     dispatch(seTgetErrorNull())
  //   }
  // }, [getError])

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    dispatch(getUser());
    dispatch(getBusinessOrderData(true, handleResponse));
    setScreenLoading(false)

  }, [dispatch]);

  // Connect socket only if the order is found
  const handleResponse = (status) => {
    if (status) {
      // Initialize socket connection
      const newSocket = io("https://socket.timelink.au", {
        transports: ["websocket"],
      });

      // Listen for order updates
      newSocket.on("orderUpdated", (data) => {
        console.log("Socket update received:", data);
        // alert("Update arrived from sockets");
        dispatch(getBusinessOrderData(true, () => false));
      });
      const user = JSON.parse(localStorage.getItem("userDetails"));
      // Register user after socket is connected and userDetail is available
      newSocket.emit("registerUser", user.id);
      console.log(`User registered with socket: ${user.id}`);

      // Save socket in state
      setSocket(newSocket);
    } else {
      navigate("/account");
    }
  };

  // Cleanup socket on component unmount
  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);
  // useEffect(() => {
  //   setInterval(() => {
  //     dispatch(getBusinessOrderData(false, handleResponse));
  //     setIsLoadingOnPageLoad(false);
  //   }, 5000);
  // }, [dispatch]);

  useEffect(() => {
    console.log("userdetails updated ", userDetail);
    if (userDetail) {
      setIsLoadingOnPageLoad(false);
      // const startTime = moment(userDetail.start_time, "HH:mm:ss");
      const endTime = moment(userDetail.end_time, "HH:mm:ss");
      const currentTime = moment();

      if (currentTime.isBefore(endTime)) {
        const duration = moment.duration(endTime.diff(currentTime));
        const remainingTimeString = formatDuration(duration);
        setRemainingTime(remainingTimeString);
      } else {
        setRemainingTime("00:05:30");
      }
    }
  }, [userDetail]);

  const formatDuration = (duration) => {
    const hours = duration.hours().toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    const seconds = duration.seconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const UpdateUserOrderStatus = (orderId) => {
    setIsOrderPickedUpClicked(true);
    let data = {
      order_id: orderId,
      picked_up: 1,
      order_status: "Complete", //Complete,Notified,Complete and Notified,Cancel
    };
    dispatch(UpdateOrderStatus(data, handleUpdateOrderResponse));
  };

  const handleUpdateOrderResponse = (data) => {
    if (orderDetail?.businessData?.campaign?.length > 0) {
      setCampaignModal(true);
      setHasCampaignDispalyed(true);
    }
    dispatch(getBusinessOrderData(true, handleResponse));
  };

  useEffect(() => {
    if (orderDetail) {
      setOrderTime({
        startTime: orderDetail?.start_time,
        endTime: orderDetail?.end_time,
      });
    }
    if (orderDetail?.businessData?.surveys?.length) {
      setTimeout(() => {
        console.info("came to open survye modal ");
        if (!hasSurveyOpened) {
          setHasSurveyOpened(true);
          console.info("should open survye modal ");
          setOpenModalSurvey(true);
        } else {
          console.info(
            "should not open survye modal as it is already in open state ",
            hasSurveyOpened
          );
        }
        // setCampaignModal(true);
        // setHasCampaignDispalyed(true);
      }, 60000);
    }
    if (orderDetail?.is_notified == 1) {
      const timeoutId = setTimeout(() => {
        if (orderDetail?.businessData?.surveys?.length >= 1) {
        } else {
          // handleOpenFeedBack();
        }
        setGoogleReviewLink(
          orderDetail?.businessData?.settings?.google_review_link
        );
      }, 100);

      return () => clearTimeout(timeoutId);
    } else {
    }
  }, [orderDetail]);

  useEffect(() => {
    if (orderDetail?.businessData?.campaign?.length > 0) {
      setRandomSelecetedCampaing(
        Math.floor(
          Math.random() * orderDetail?.businessData?.b_campaigns?.length
        ) || 0
      );
    }
  }, [orderDetail]);

  const handleSubmitSurvey = (questionId) => {
    var user = JSON.parse(localStorage.getItem("userDetails"));
    var data;
    if (currentQuestionIndex < orderDetail?.businessData?.surveys?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      if (selectedOption.length != 0) {
        if (otherFieldVisible) {
          data = {
            option_number: selectedOption,
            option_value: otherFieldValue,
            question_id: questionId,
            user_id: user.id,
            business_id: orderDetail?.businessData?.id,
          };
        } else {
          data = JSON.parse(selectedOption);
          data.question_id = questionId;
          data.user_id = user.id;
          data.business_id = orderDetail?.businessData?.id;
        }
        setSelectedOption("");
        setOtherFieldValue("");
      }
    } else {
      // If no more questions, close the modal
      if (selectedOption.length != 0) {
        if (otherFieldVisible) {
          data = {
            option_number: selectedOption,
            option_value: otherFieldValue,
            question_id: questionId,
            user_id: user.id,
            business_id: orderDetail?.businessData?.id,
          };
        } else {
          data = JSON.parse(selectedOption);
          data.question_id = questionId;
          data.user_id = user.id;
          data.business_id = orderDetail?.businessData?.id;
        }
      }
      setSurveyDataModal(false);
      handleOpenFeedBack();
    }
    dispatch(submitSurvey(data, handleSubmitSurveyclose));
  };
  function handleSubmitSurveyclose(data) {
    toast.success("Great! Answer submited Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  const handleSkipQuestion = () => {
    if (currentQuestionIndex < orderDetail?.businessData?.surveys?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // If no more questions, close the modal
      setSurveyDataModal(false);
      // handleOpenFeedBack();
    }
  };
  function handleOpenFeedBack() {
    return false;
    if (
      orderDetail?.businessData?.settings?.get_feedback != 1 ||
      orderDetail?.hasProvidedFeedback
    ) {
      handleOpenGoogleReview();
      return false;
    } else {
      setFeedbackModal(true);
    }
  }
  function handleOpenGoogleReview() {
    if (orderDetail?.businessData?.settings?.get_google_review != 1) {
      return false;
    } else {
      if (orderDetail?.status === "Pending") {
        setOpenModalGoggleReview(true);
      }
    }
  }

  function handleSubmitFeedback() {
    var user = JSON.parse(localStorage.getItem("userDetails"));
    if (selectedReaction == null || feedbackMessage.length < 0) {
      toast.error("Both reaction and message are required!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    var data = {
      reaction: reactionEmojies[selectedReaction],
      message: feedbackMessage,
      order_id: orderDetail?.id,
      user_id: user.id,
      business_id: orderDetail?.businessData?.id,
      is_anonymous: anonymous ? 1 : 0,
    };
    dispatch(submitFeedback(data, handleSubmitFeedbackclose));
  }

  function handleSubmitFeedbackclose(data) {
    toast.success("Great! Feedback is submited Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setFeedbackModal(false);
    handleOpenGoogleReview();
  }

  function handleUpdateSurveyInteractionStatus(id, status) {
    var data = {
      survey_id: id,
      order_id: orderDetail?.id,
      interaction_status: status,
    };
    dispatch(updateSurveyInteractionStatus(data));
  }

  function handleUpdateMarketingInteractionStatus(id, status) {
    setHasCampaignDispalyed(true);
    var data = {
      campaign_id: id,
      interaction_status: status,
      order_id: orderDetail?.id,
      business_id:
        orderDetail?.businessData?.campaign[randomSelecetedCampaing]
          ?.business_id,
      bk: encodeURIComponent(encode(orderDetail?.businessData?.id)),
    };
    dispatch(updateMarketingInteractionStatus(data));
  }

  //time calculate
  const [difference, setDifference] = useState(null);
  const [orderTime, setOrderTime] = useState(null);

  useEffect(() => {
    if (orderTime && orderTime.startTime) {
      const calculateDifference = () => {
        const startParts = orderTime.startTime?.split(":")
          .map((part) => parseInt(part));
        const endParts = orderTime.endTime
          ? orderTime.endTime?.split(":").map((part) => parseInt(part))
          : [
            new Date().getHours(),
            new Date().getMinutes(),
            new Date().getSeconds(),
          ];
        const start = new Date(
          0,
          0,
          0,
          startParts[0],
          startParts[1],
          startParts[2]
        );
        const end = new Date(0, 0, 0, endParts[0], endParts[1], endParts[2]);
        const diffInMilliseconds = end - start;
        const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor(
          (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);
        setDifference(hours > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`);
      };

      calculateDifference();

      // Recalculate the difference every second if the end time is null
      const interval = setInterval(() => {
        if (!orderTime.endTime) {
          calculateDifference();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [orderTime]);

  function handleMayBeLaterClick() {
    orderDetail?.businessData?.surveys?.map((survey) => {
      var data = {
        survey_id: survey.id,
        order_id: orderDetail?.id,
        interaction_status: "skipped",
      };
      dispatch(updateSurveyInteractionStatus(data));
    });
  }

  function convertTo12HourFormat(time24) {
    // Split the time into hours and minutes
    const [hours, minutes] = time24?.split(':').map(Number);

    // Determine AM/PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12;

    // Format the time in 12-hour format
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
  }
  return (
    <>
      <ToastContainer />
      <div className="container-fluid p-0 poppins">
        {isLoading || screenLoading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="container poppins">
            <div className="d-flex justify-content-between align-items-center  mt-3">
              <div onClick={() => navigate("/account")}>
                <div
                  role="button"
                  className=" w-8 h-8 flex justify-center items-center text-white bg-customBlue px-2 py-1 rounded-full "
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_5261_16697)">
                      <path
                        d="M17.25 9.75C17.25 12.0375 15.975 14.0625 14.625 15.3V16.5C14.625 17.25 15 18.4125 18.75 19.125C19.8088 19.3276 20.7904 19.8207 21.5851 20.5492C22.3798 21.2776 22.9562 22.2128 23.25 23.25H0.75C0.922895 22.2187 1.41321 21.267 2.15263 20.5276C2.89204 19.7882 3.84371 19.2979 4.875 19.125C8.625 18.45 9.375 17.25 9.375 16.5V15.3C8.025 14.0625 6.75 12.075 6.75 9.75V6.15C6.75 2.25 9.525 0.75 12 0.75C14.475 0.75 17.25 2.25 17.25 6.15V9.75Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5261_16697">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div>
                {" "}
                <img src={finalLogo} width={160} alt="" />
              </div>
              <div
                role="button"
                className="  text-white bg-customBlue px-2 py-1 rounded-full uppercase"
                onClick={() => navigate("/questGame")}
              >
                play
              </div>
            </div>
            <div className="bg-[#D7E1FE] shadow-[-3px_7px_13px_0_#00000017] gap-4 rounded-lg mt-3 p-3 flex">
              <div className="w-24 h-24 flex bg-white rounded-xl justify-center items-center">
                <img
                  src={orderDetail?.businessData?.image || loyalty}
                  alt="w8"
                  className="h-14 w-14 object-cover"
                />{" "}
              </div>
              <div>
                <h3 className="mb-1 text-xl font-semibold">
                  {orderDetail?.businessData?.business_name || "KALZ BRZR"}
                </h3>
                <p className="font-semibold text-base text-[#555555] mb-1">
                  {orderDetail?.start_time ? convertTo12HourFormat(orderDetail?.start_time) : "01 Sep, 12:30 PM"}
                </p>
                {orderDetail.is_loyalty == 0 ? (
                  ""
                ) : (
                  <div
                    className="text-[11px]  gap-2 bg-customBlue mt-1 text-white rounded-lg inline-flex items-center px-2 p-1"
                    style={{ textTransform: "capitalize" }}
                  >
                    <svg
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.65676 1.44122C5.06069 0.852926 4.09492 0.852926 3.49893 1.44122C2.88398 2.04814 2.94128 2.93025 3.41787 3.48754H1.71558C1.32006 3.48754 1 3.80342 1 4.19378V6.48905H1.35779V12.3155C1.35779 12.7059 1.67785 13.0217 2.07337 13.0217H12.8071C13.2026 13.0217 13.5226 12.7059 13.5226 12.3155V6.48905H13.8804V4.19378C13.8804 3.80342 13.5604 3.48754 13.1649 3.48754H11.4626C11.9392 2.93029 11.9965 2.04818 11.3815 1.44122C10.7854 0.852926 9.81966 0.852926 9.22367 1.44122L7.4403 3.2013L5.65676 1.44122ZM3.8783 1.81572C4.26475 1.43432 4.89088 1.43432 5.27731 1.81572L6.96563 3.482H4.57774C3.69654 3.482 3.25497 2.43092 3.8783 1.81572ZM1.71546 4.01724H5.65115V5.95939H1.53656V4.1938C1.53656 4.09655 1.61693 4.01724 1.71546 4.01724ZM9.22905 12.4921H12.8069C12.9055 12.4921 12.9858 12.4128 12.9858 12.3155V6.48907H9.22905V12.4921ZM8.69236 6.48907V12.4921H6.18783V6.48907H8.69236ZM9.22905 5.95939H13.3436V4.1938C13.3436 4.09655 13.2633 4.01724 13.1647 4.01724H9.22905V5.95939ZM8.69236 4.01724V5.95939H6.18783V4.01724H8.69236ZM1.89435 6.48907H5.65115V12.4921H2.07325C1.97472 12.4921 1.89435 12.4128 1.89435 12.3155V6.48907ZM9.60294 1.81572C9.98938 1.43432 10.6155 1.43432 11.0019 1.81572C11.6253 2.43092 11.1836 3.482 10.3024 3.482H7.91454L9.60294 1.81572Z"
                        fill="white"
                        stroke="white"
                        stroke-width="0.429348"
                      />
                    </svg>
                    Loyalty
                  </div>
                )}
              </div>
            </div>
            <div className=" bg-gray-100 rounded-lg  mt-4 ">
              <div className="p-4 text-center border-b-[1.5px] border-dashed border-gray-400">
                <h4 className="text-sm font-semibold m-0 text-[#545454]">
                  REFERENCE ID
                </h4>
                {orderDetail && (
                  <h1 className="mb-0 text-[75px] font-bold text-customBlue ">
                    {orderDetail.order_id || "75"}
                  </h1>
                )}
              </div>
              <div className="mt-3 p-4">
                <Stepper status={orderDetail?.status || "Complete"} />
              </div>
            </div>
            <div className="bg-gray-100 rounded-lg  shadow-[-3px_7px_13px_0_#00000017] mt-4 p-3">
              <div className="">
                <div className="flex  items-start gap-2 ">
                  <img src={location} width={17} className="mt-1" alt="w8" />
                  <div>
                    <p className="text-darked fw-medium  mb-0">
                      {orderDetail?.businessData?.city}
                    </p>

                  </div>
                </div>
                <div className=" flex  items-center gap-2 mt-2">
                  <div>
                    <img src={timer} alt="w8" />
                  </div>
                  <div>
                    <p className="mb-0 text-darked font-13-86 fw-medium ">
                      {difference || "00:05:30"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center d-flex justify-content-center mt-3 pb-1">
              {!orderDetail?.status?.includes("Complete") &&
                !isOrderPickedUpClicked && (
                  <button
                    disabled={orderDetail?.is_notified !== 1}
                    className={`${orderDetail?.is_notified == 1
                      ? " order-pickup-btn-order1"
                      : "order-pickup-btn-order "
                      } 
              pt-3 pb-3 mt-2 mb-3`}
                    style={
                      orderDetail?.is_notified !== 1
                        ? { cursor: "not-allowed" }
                        : {}
                    }
                    onClick={() => UpdateUserOrderStatus(orderDetail?.id)}
                  >
                    Order picked up
                  </button>
                )}
            </div>
            {/* =================
          30 SECOND MODAL
          ================= */}
            <Modal
              open={openModalSurvey}
              className="d-flex align-items-center "
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableScrollLock={true}
            >
              <Box className=" p-2 d-flex flex-column" sx={styleSurvey}>
                {/* <div className="text-end pe-3 pt-3">
                <span
                  role="button" onClick={() => { setOpenModalSurvey(false); setCampaignModal(true); }}
                  className=" inter fw-semibold text-gray font-20"
                >
                  <img src={close} width={24} height={24} alt="w8" />
                </span>

              </div> */}
                <div className="flex-grow-1 d-flex align-items-center px-3">
                  <div className="w-100">
                    <div className=" p-3 pb-0 pt-2 position-relative">
                      <p
                        className="text-center mx-auto fw-light helvetica-thin "
                        style={{ fontSize: "24px" }}
                      >
                        Got a moment?
                      </p>
                    </div>
                    <div className=" container">
                      <div className=" text-center mt-1 mb-3">
                        <p
                          className="font-15 helvetica-light"
                          style={{ color: "#636366" }}
                        >
                          Could you spare 30 seconds to take part in a short
                          survey? Your answers help support your local
                          businesses.
                        </p>
                      </div>
                      <br />
                      <div className="row justify-content-center ">
                        <div className="col-7">
                          <Button
                            type="button"
                            className="btn-fill-blue p-2 helvetica-medium fw-medium w-100 rounded-5  text-white mx-auto font-14-69 "
                            onClick={() => {
                              setOpenModalSurvey(false);
                              setSurveyDataModal(true);
                            }}
                          >
                            Sure
                          </Button>
                        </div>
                      </div>
                      <div className="row justify-content-center ">
                        <div className="mt-3  col-7 text-center">
                          <Link
                            className="text-decoration-underline font-14- helvetica-light"
                            style={{ color: "#636366" }}
                            onClick={() => {
                              setOpenModalSurvey(false);
                              handleMayBeLaterClick();
                            }}
                          >
                            Maybe later{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
            {/* =============
         Recommended modal
         ============= */}
            <Modal
              open={surveyDataModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableScrollLock={true}
            >
              <Box className=" p-4 pb-0 px-2 w-100 " sx={style}>
                <div className="">
                  <div className="pt-2 p-3  position-relative ">
                    <h6
                      className="Roobert-bold text-start  fw-bold font-24"
                      style={{ width: "90%" }}
                    >
                      {/* How likely are you to <br /> recommend us? */}
                      {
                        orderDetail?.businessData?.surveys[currentQuestionIndex]
                          ?.question
                      }
                    </h6>
                    {/* <Button
                    onClick={() => setSurveyDataModal(false)}
                    className="position-absolute top-0 end-0 poppins fw-semibold text-gray font-20"
                    variant="text"
                  >
                    <img src={close} width={24} height={24} alt="w8" />
                  </Button> */}
                  </div>
                  <div
                    className="p-3 pb-0 pt-0 d-flex flex-column justify-content-between"
                    style={{ height: "42vh" }}
                  >
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                      >
                        {orderDetail?.businessData?.surveys?.length > 0 &&
                          orderDetail?.businessData?.surveys[
                            currentQuestionIndex
                          ].options.map((index) => {
                            return index.is_text_field != 1 ? (
                              <FormControlLabel
                                value={`{"option_value":"${index.option_value}" , "option_number":"${index.option_number}"}`}
                                onChange={(e) => {
                                  setOtherFieldVisible(false);
                                  setSelectedOption(e.target.value);
                                }}
                                control={<Radio style={{ color: "#3461FD" }} />}
                                label={index.option_value}
                              />
                            ) : (
                              <FormControlLabel
                                value={index.option_number}
                                onChange={(e) => {
                                  setOtherFieldVisible(true);
                                  setSelectedOption(e.target.value);
                                }}
                                control={<Radio style={{ color: "#3461FD" }} />}
                                label={index.option_value}
                              />
                            );
                          })}

                        {/* <FormControlLabel value="Very-likely" control={<Radio style={{ color: '#3461FD' }} />} label="Very likely" />
                      <FormControlLabel value="likely" control={<Radio style={{ color: '#3461FD' }} />} label="Likely" />
                      <FormControlLabel value="neutral" control={<Radio style={{ color: '#3461FD' }} />} label="Neutral" />
                      <FormControlLabel value="unlikely" control={<Radio style={{ color: '#3461FD' }} />} label="Unlikely" />
                      <FormControlLabel value="very-Unlikey" control={<Radio style={{ color: '#3461FD' }} />} label="Very Unlikey" />
                      <FormControlLabel value="not-sure" control={<Radio style={{ color: '#3461FD' }} />} label="Not sure" /> */}
                      </RadioGroup>
                      {otherFieldVisible && (
                        <input
                          className="my-2 rounded p-2 border"
                          style={{
                            borderColor: "#D9D9D9",
                            height: 65,
                            outline: 2,
                            outlineColor: "#3867FA",
                          }}
                          value={otherFieldValue}
                          onChange={(e) => setOtherFieldValue(e.target.value)}
                        />
                      )}
                    </FormControl>
                    <div className=" row justify-content-center">
                      <div className="col-6">
                        <Button
                          type="button"
                          className="btn-border p-3 py-2 rounded-3  w-100 text-white mx-auto font-14-69 montserrat"
                          onClick={() => {
                            handleSkipQuestion();
                            handleUpdateSurveyInteractionStatus(
                              orderDetail?.businessData?.surveys[
                                currentQuestionIndex
                              ].id,
                              "skipped"
                            );
                          }}
                        >
                          Skip
                        </Button>
                      </div>
                      <div className="col-6">
                        <Button
                          type="button"
                          className="btn-fill-blue p-3 py-2 rounded-3 w-100 mb-3 text-white mx-auto font-14-69 montserrat"
                          onClick={() => {
                            handleSubmitSurvey(
                              orderDetail?.businessData?.surveys[
                                currentQuestionIndex
                              ].id
                            );
                            handleUpdateSurveyInteractionStatus(
                              orderDetail?.businessData?.surveys[
                                currentQuestionIndex
                              ].id,
                              "completed"
                            );
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
            {/* =============
          Order Complete
          =========== */}
            <Modal
              open={campaignModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableScrollLock={true}
            >
              <Box className=" p-4 px-2 w-100 " sx={style}>
                {mblTerms ? (
                  <div className="">
                    <div className="pt-0 p-3 position-relative ">
                      <h6
                        className="inter text-center mx-auto fw-semibold font-15"
                        id="exampleModalLabel"
                      >
                        Interesting Offer
                      </h6>
                      {/* <Button
                    onClick={() => { setCampaignModal(false); handleOpenFeedBack() }}

                    className="position-absolute top-0 end-0 poppins fw-semibold text-gray font-20"
                    variant="text"
                  >
                    <img src={close} width={24} height={24} alt="w8" />

                  </Button> */}
                    </div>
                    <div className="p-3 pt-0 container">
                      <div className="row justify-content-center">
                        <div className="col-9 rounded-2">
                          <img
                            src={
                              orderDetail?.businessData?.campaign[
                                randomSelecetedCampaing
                              ]?.offer_images
                            }
                            className="w-100 rounded-2"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="text-start px-4 mt-3 mb-3">
                        <h6 className="font-13 fw-semibold inter">
                          {
                            orderDetail?.businessData?.campaign[
                              randomSelecetedCampaing
                            ]?.offer_heading
                          }
                        </h6>
                        <p
                          className="font-13 fw-medium inter"
                          style={{ color: "#5B5B5B" }}
                        >
                          {
                            orderDetail?.businessData?.campaign[
                              randomSelecetedCampaing
                            ]?.offer_subheading
                          }
                          <u role="button" onClick={() => setMblTerms(false)}>
                            {" "}
                            Terms & Conditions
                          </u>{" "}
                          apply. Details will be applied to account{" "}
                          {userDetail?.phone}.
                        </p>
                      </div>
                      <div className="container p011t-3">
                        <div className="row justify-content-center">
                          <div className="col-9">
                            {/* <Button
                          type="button"
                          className="btn-fill-blue p-3 rounded-3 w-100 mb-3 text-white mx-auto font-14-69 montserrat"
                          onClick={() => { setCampaignYesModal(true); setCampaignModal(false) }}
                        >
                          Yes Please
                        </Button>
                        <Button
                          type="button"
                          className="btn-fill-gray p-3 rounded-3  w-100 text-white mx-auto font-14-69 montserrat"
                          onClick={() => { handleOpenFeedBack(); setCampaignModal(false) }}
                        >
                          No Thanks
                        </Button> */}
                            <div className="row justify-content-center mt-2">
                              <div className="col-9">
                                <Button
                                  type="button"
                                  className="btn-fill-blue p-2 helvetica-medium fw-medium w-100 rounded-5  text-white mx-auto font-14-69 "
                                  onClick={() => {
                                    setCampaignYesModal(true);
                                    setCampaignModal(false);
                                    handleUpdateMarketingInteractionStatus(
                                      orderDetail?.businessData?.campaign[
                                        randomSelecetedCampaing
                                      ]?.id,
                                      "completed"
                                    );
                                  }}
                                >
                                  Sure
                                </Button>
                              </div>
                            </div>
                            <div className="row justify-content-center ">
                              <div className="mt-3  col-7 text-center">
                                <Link
                                  className="text-decoration-underline font-14- helvetica-light"
                                  style={{ color: "#636366" }}
                                  onClick={() => {
                                    handleOpenFeedBack();
                                    setCampaignModal(false);
                                    handleUpdateMarketingInteractionStatus(
                                      orderDetail?.businessData?.campaign[
                                        randomSelecetedCampaing
                                      ]?.id,
                                      "skipped"
                                    );
                                  }}
                                >
                                  {orderDetail?.businessData?.campaign[
                                    randomSelecetedCampaing
                                  ]?.retargetting
                                    ? "Maybe later"
                                    : "NO, Thank you"}{" "}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-2 px-3 pb-5">
                    <div className="d-flex justify-content-between align-items-start   ">
                      <div>
                        <h4 className="fw-bold mb-0 inter">Offer Conditions</h4>
                        <p
                          className="inter text-center text-dark-gray mb-1 font-14"
                          style={{ color: "#898989" }}
                        >
                          Terms & Conditions apply.
                        </p>
                      </div>

                      <img
                        role="button"
                        src={termsCondation}
                        onClick={() => setMblTerms(true)}
                        alt="w8"
                        width={25}
                      />
                    </div>
                    <p
                      className="font-14 pb-2 mt-2 fw-medium inter"
                      style={{ color: "#535353", wordBreak: "break-all" }}
                    >
                      {orderDetail?.businessData?.campaign?.nitty_gritty_stuff}
                    </p>
                  </div>
                )}
              </Box>
            </Modal>
            {/* Marketing yes model  */}
            <Modal
              open={campaignYesModal}
              className="d-flex align-items-center "
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableScrollLock={true}
            >
              <Box className=" p-2 d-flex flex-column" sx={styleSurvey}>
                {/* <div className="text-end pe-3 pt-3">
                <span
                  role="button" onClick={() => { setCampaignYesModal(false); handleOpenFeedBack(); }}
                  className=" inter fw-semibold text-gray font-20"
                >
                  <img src={close} width={24} height={24} alt="w8" />
                </span>

              </div> */}
                <div className="flex-grow-1 d-flex align-items-center px-3">
                  <div className="w-100">
                    <div className=" p-3 pb-1 pt-2 position-relative d-flex flex-column justify-content-center align-items-center">
                      <img
                        className="my-3"
                        src={greentick}
                        width={30}
                        height={30}
                        alt="w8"
                      />
                      <p
                        className="text-center mx-auto fw-light helvetica-thin "
                        style={{ fontSize: "24px" }}
                      >
                        Thanks for signing up!
                      </p>
                    </div>
                    <div className=" container">
                      <div className=" text-center mt-1 mb-3">
                        <p
                          className="font-15 helvetica-light p-1"
                          style={{ color: "#636366" }}
                        >
                          You will receive an SMS with your exclusive offer and
                          how to redeem within 24 Hours. Your offer can also be
                          found within your setting page under the ‘Offers &
                          Loyalty’ section.
                        </p>
                      </div>
                      <br />
                      <div className="row justify-content-center ">
                        <div className="col-9">
                          <Button
                            type="button"
                            className="btn-fill-black p-2 helvetica-medium fw-medium w-100 rounded-5  text-white mx-auto font-14-69 "
                            onClick={() => {
                              setCampaignYesModal(false);
                            }}
                            style={{ backgroundColor: "black" }}
                          >
                            Finish
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
            {/* =========

          {/* =============
        Hey there MODAL
         ============= */}
            <Modal
              open={feedbackModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableScrollLock={true}
            >
              <Box className=" p-4 pb-0 px-2 w-100 " sx={style}>
                <div className="">
                  <div className="pt-2 p-3 pb-1 position-relative ">
                    <h6 className="inter text-start  fw-bold font-24">
                      Hey there!
                    </h6>
                    <p className="font-14  inter" style={{ color: "#898989" }}>
                      Leave us feedback so we know how we’re doing?
                    </p>
                    {/* <Button
                    onClick={() => { setFeedbackModal(false); handleOpenGoogleReview(); }}
                    className="position-absolute top-0 end-0 poppins fw-semibold text-gray font-20"
                    variant="text"
                  >
                    <img src={close} width={24} height={24} alt="w8" />
                  </Button> */}
                  </div>

                  <div className="p-3 pb-0 pt-0 container">
                    <div className="hyModal-textare">
                      <div className="d-flex flex-row justify-content-between">
                        {reactionEmojies.map((value, index) => {
                          return (
                            <span
                              onClick={() => {
                                setSelectedReaction(index);
                              }}
                              style={
                                selectedReaction == index
                                  ? { backgroundColor: "#3461FD" }
                                  : {}
                              }
                            >
                              {value}
                            </span>
                          );
                        })}
                      </div>
                      <textarea
                        rows="7"
                        className="form-control mt-4 font-14 "
                        placeholder="Tell us what you think..."
                        value={feedbackMessage}
                        onChange={(e) => {
                          setFeedbackMessage(e.target.value);
                        }}
                      ></textarea>
                      <p
                        className="font-12 mt-3 inter text-start"
                        style={{ color: "#D1D5DB" }}
                      >
                        Your input, our improvement, your privacy.
                      </p>
                    </div>
                    <div className="d-flex flex-row align-content-center gap-2">
                      <input
                        type="checkbox"
                        checked={anonymous}
                        onChange={() => {
                          setAnonymous(!anonymous);
                        }}
                      />
                      <p
                        className="font-12 mt-3 inter text-start"
                        style={{ color: "#000" }}
                      >
                        Submit my feedback anonymously.
                      </p>
                    </div>
                    <div className="mt-3 row justify-content-center">
                      <div className="col-6">
                        <Button
                          type="button"
                          className="btn-border p-3 py-2 rounded-3  w-100 text-white mx-auto font-14-69 montserrat"
                          onClick={() => {
                            setFeedbackModal(false);
                            handleOpenGoogleReview();
                          }}
                        >
                          Skip
                        </Button>
                      </div>
                      <div className="col-6">
                        <Button
                          type="button"
                          className="btn-fill-blue p-3 py-2 rounded-3 w-100 mb-3 text-white mx-auto font-14-69 montserrat"
                          onClick={() => {
                            handleSubmitFeedback();
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
            {/* =============
        Hey there MODAL
         ============= */}
            <Modal
              open={openModalGoggleReview}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableScrollLock={true}
            >
              <Box className=" p-4 pb-0 px-2 w-100 " sx={style}>
                <div className="">
                  <div className="pt-2 p-3 pb-1  text-center">
                    <h5 className="inter fw-semibold">Thank you for that!</h5>
                    <Button
                      onClick={() => {
                        setOpenModalGoggleReview(false);
                        navigate("/account");
                      }}
                      className="position-absolute top-0 end-0 poppins fw-semibold text-gray font-20"
                      variant="text"
                    >
                      <img src={close} width={24} height={24} alt="w8" />
                    </Button>
                  </div>

                  <div className="p-3 pb-0 pt-0 container ">
                    <div className="row justify-content-center ">
                      <div className="col-8 my-4">
                        <img src={GoggleReview} className="w-100" alt="w8" />
                      </div>
                      <p
                        className="font-13 mb-4 text-center inter"
                        style={{ color: "#5B5B5B" }}
                      >
                        Your feedback goes a long way! How about <br /> a google
                        review?
                      </p>
                    </div>

                    <div className="mt-3 row justify-content-center mb-4">
                      <div className="col-12">
                        <a
                          href={googleReviewLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            type="button"
                            className="btn-border px-2 py-2 rounded-3 justify-content-between w-100 align-items-center text-white mx-auto font-14-69 montserrat"
                          >
                            <img
                              src={goggle}
                              alt="w8"
                              width={50}
                              height={50}
                              className="h-100 px-1"
                            />
                            <p className="mb-0 font-14 inter">Google Review</p>
                            <img
                              src={goggle}
                              alt="w8"
                              width={50}
                              height={50}
                              className="px-1"
                              style={{ visibility: "hidden" }}
                            />
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};
export default Order;
