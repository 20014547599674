import React, { useDebugValue, useEffect, useState } from 'react'
import { BsFacebook } from 'react-icons/bs';
import { FaApple, FaCircleNotch, FaMobileAlt } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { IoClose } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom';
import useAOS from '../customHook/useAOS';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { useDispatch, useSelector } from 'react-redux';
import { seTgetErrorNull, signInWithGoogle } from '../redux/slice/UserSlice';
import { ToastContainer, toast } from 'react-toastify';
import { FacebookAuthProvider, getRedirectResult, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { auth } from "../firebase"; // Adjust the path if necessary


const LoginOrSignup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem('accessToken');
    useEffect(() => {
        if (token) {
            if (localStorage.getItem('redirectAfterLogin')) {
                navigate(localStorage.getItem('redirectAfterLogin'))
            }
            navigate('/account')
        }
    }, [])
    const { getError } = useSelector((state) => { return state.user })
    useAOS();
    const [loading, setLoading] = useState(false); // Step 1: Loading state
    const [email, setEmail] = useState(undefined);
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            dispatch(seTgetErrorNull())
        }
    }, [getError])


    const handleFacebookSignIn = async () => {
        const provider = new FacebookAuthProvider();
        try {
            console.log('facebook login called');
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            if (user) {
                var d = {
                    email: user.email,
                    firstname: user.displayName,
                }
                dispatch(
                    signInWithGoogle(d, (data) => {
                        if (data?.status && (!data?.data?.phone || !data?.data?.is_phone_verified)) {
                            setEmail(data?.data?.email);
                            navigate('/phonenumber', { state: { email: data?.data?.email } })
                            toast.error(
                                "We could not find your phone number from your google account or you have added previously but did not verify it!",
                                {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        } else {
                            if (data?.status) {
                                if (!data?.isOrderFound) {
                                    navigate("/account");
                                } else {
                                    navigate("/order");
                                }
                            }
                            if (data?.error) {
                                console.log('reached inside the error condition')
                                toast.error(
                                    data?.message,
                                    {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    }
                                );
                            }
                        }
                    })
                );
            }
        } catch (error) {
            console.error("Error during sign-in:", error.message);
        }
    };

    const handleRedirectResult = async () => {
        try {
            // Get the result of the redirect (user info after login)
            const result = await getRedirectResult(auth);

            if (result) {
                const user = result.user;
                console.log("User Info:", user);
            } else {
                console.log("No result found, user might have cancelled login.");
            }
        } catch (error) {
            console.error("Error during redirect result:", error.message);
        }
    };
    useEffect(() => {
        handleRedirectResult();
    }, []);
    return (
        <>
            <div data-aos="fade-left" data-aos-duration="500" className="position-fixed w-100 top-0 bg-white z-3 ">

                <div className='flex items-center justify-content-between p-3 manrope'>
                    <p className='font-15 fw-semibold mb-0  flex items-center'><IoClose size={18} onClick={() => navigate(-1)} />&nbsp;Profile</p>
                    <p className='font-13 text-gray mb-0 flex items-center '>Existing account? <Link to="/loginOrSignup" className='text-blue fw-bold'>
                        Sign in</Link></p>
                </div>
                <hr className='border-0 mt-0 mb-1' style={{ height: '.5px', opacity: '50%', backgroundColor: '#CACACA' }} />
            </div>

            <div data-aos="fade-left" data-aos-duration="500" className='p-3 manrope' style={{ marginTop: "54px" }}>
                <h3 className='fw-bold '>
                    Log in or sign up
                </h3>
                <p className='font-13'>Save your previous orders, loyalty, rewards & more with a TimeLink account.</p>
                <div>
                    <LoginSocialGoogle
                        redirect={true}
                        ux_mode="redirect"
                        redirect_uri="http://localhost:3000/loginOrSignup"
                        client_id={'212290965015-r0v8ahr9q13u3upesu87bisjvtoi82n7.apps.googleusercontent.com' || ''}
                        scope="profile email https://www.googleapis.com/auth/userinfo.email" // Add "email" to the scope
                        onLoginStart={() => { console.log('oauth 2.0 started') }}
                        onResolve={({ provider, data }) => {
                            console.log(data);
                            var d = {
                                email: data.email,
                                firstname: data.name,
                            }
                            dispatch(
                                signInWithGoogle(d, (data) => {
                                    if (data?.status && (!data?.data?.phone || !data?.data?.is_phone_verified)) {
                                        setEmail(data?.data?.email);
                                        navigate('/phonenumber', { state: { email: data?.data?.email } })
                                        toast.error(
                                            "We could not find your phone number from your google account or you have added previously but did not verify it!",
                                            {
                                                position: "top-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                            }
                                        );
                                    } else {
                                        if (data?.status) {
                                            if (!data?.isOrderFound) {
                                                navigate("/account");
                                            } else {
                                                navigate("/order");
                                            }
                                        }
                                        if (data?.error) {
                                            console.log('reached inside the error condition')
                                            toast.error(
                                                data?.message,
                                                {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "light",
                                                }
                                            );
                                        }
                                    }
                                })
                            );
                            console.log('data object to be sent in signInWithGoogle', d)
                            //console.log(provider, data)
                        }}
                        onReject={(err) => {
                            console.log(err)
                        }}
                    >
                        <button
                            className={`btn-google w-100 Roobert-bold fw-bold text-dark flex items-center justify-content-center gap-2 py-3 px-4 rounded-3`}

                        >
                            {loading ?
                                (

                                    <div className={`myloader`}>
                                        <FaCircleNotch />
                                    </div>)
                                :
                                <>
                                    <FcGoogle size={20} />
                                    <span>Continue with Google</span>
                                </>


                            }
                        </button>
                    </LoginSocialGoogle>
                    {/* <button type='button' className='w-100 mt-3 bg-white font-15 p-3 rounded-3 border border-secondary-subtle font-15 fw-semibold text-body'><FcGoogle size={20} /> &nbsp;&nbsp;Continue with Google</button> */}
                    <button type='button' className='w-100 mt-3 bg-white font-15 p-3 rounded-3 border border-secondary-subtle font-15 fw-semibold text-body flex items-center justify-center'><FaApple size={20} /> &nbsp;&nbsp;Continue with Apple</button>
                    <button onClick={handleFacebookSignIn} type='button' className='w-100 mt-3 bg-white font-15 p-3 rounded-3 border border-secondary-subtle font-15 fw-semibold text-body flex items-center justify-center'><BsFacebook className='text-blue' size={20} /> &nbsp;&nbsp;Continue with Facebook</button>
                    <div className="mt-3 d-flex align-items-center justify-content-between">
                        <hr style={{ height: 1, borderWidth: 1, width: '40%' }} />
                        <span>or</span>
                        <hr style={{ height: 1, borderWidth: 1, width: '40%' }} />
                    </div>
                    <Link to='/phoneNumber'>
                        <button type='button' className='w-100 mt-3 bg-white font-15 p-3 rounded-3 border border-secondary-subtle font-15 fw-semibold text-body flex items-center justify-center'><FaMobileAlt size={20} /> &nbsp;&nbsp;Continue with phone number</button>
                    </Link>
                    <p className='font-12 text-center mt-2'>By continuing, I agree to the Terms & Conditions & acknowledge the Privacy
                        Policy.</p>

                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default LoginOrSignup
