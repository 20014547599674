import React from "react";
import { FaCircle } from "react-icons/fa";
const Steppers = ({ status, bg }) => {
  // linked, Pending,Notified,Complete
  const statuses = ["Linked", "Preparing", "Ready", "Picked Up"];
  const currentStatus =
    status === "Pending"
      ? "Preparing"
      : status === "Pending"
      ? "Preparing"
      : status === "Notified"
      ? "Ready"
      : status === "Complete"
      ? "Picked Up"
      : "";
  return (
    <div
      className={`flex items-center justify-center gap-3 mt-4 ${
        bg ? bg : "bg-gray-100"
      }`}
    >
      {statuses.map((status, index) => {
        const isCurrent = status === currentStatus;
        const isPrevious = statuses.indexOf(currentStatus) > index;

        return (
          <div
            key={status}
            className={`flex flex-col items-center relative  ${
              isCurrent && "flex-1"
            }`}
          >
            {/* Circle and Line */}
            <div className={` flex items-center justify-center relative`}>
              {isCurrent && (
                <>
                  <div className="absolute h-[35px] min-w-[75px] start-0 flex justify-center items-center  -translate-x-[39%] -top-[32px] z-10 bg-customBlu text-white p-1.5 rounded-xl px-3 text-sm  whitespace-nowrap	">
                    <svg
                      className="absolute -top-0 start-0 "
                      width="100%"
                      height="100%"
                      viewBox="0 0 101 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M100.839 15.9446C100.839 7.13863 93.7002 0 84.8942 0H15.9446C7.13863 0 0 7.13864 0 15.9446V27.7522C0 36.5582 7.13863 43.6968 15.9446 43.6968H37.3674C39.8008 43.6968 42.1347 44.6635 43.8554 46.3843C47.4386 49.9675 53.2482 49.9675 56.8315 46.3843C58.5522 44.6635 60.886 43.6968 63.3195 43.6968H84.8942C93.7002 43.6968 100.839 36.5582 100.839 27.7522V15.9446Z"
                        fill="#2F74FA"
                      />
                    </svg>
                    <span className="z-10 relative text-xs pb-1">
                      {" "}
                      {status}
                    </span>
                  </div>
                  {/* <span className="absolute left-[50%] right-[50%] -translate-x-2/4	 z-0 -top-2 w-0 h-0 border-l-[13px] border-l-transparent border-r-[13px] border-r-transparent border-t-[20px] border-t-customBlue"></span> */}
                  {/* Arrow */}
                </>
              )}
              <span className="">
                {isCurrent && (
                  <FaCircle
                    className="text-white border-4 z-10 relative border-customBlue rounded-full"
                    size={20}
                  />
                )}
                {!isCurrent && (
                  <span className="mt-2 text-sm h-[20px] text-gray-500">
                    {status}
                  </span>
                )}
              </span>
            </div>

            {/* Progress Line */}
            <div
              className={`${
                isCurrent ? "w-full" : "w-16"
              } h-4 mt-2 rounded-full relative ${
                isPrevious
                  ? "bg-customBlue"
                  : isCurrent
                  ? currentStatus === "Picked Up"
                    ? "bg-customBlue"
                    : ""
                  : "bg-[#C0D0FF]"
              }`}
            >
              {isCurrent && currentStatus !== "Picked Up" && (
                <div className="progress w-full h-full "></div>
              )}
            </div>

            {/* Status Label */}
          </div>
        );
      })}
      <style>
        {`
          .progress {
  //  width: 100.8px;
  //  height: 16.8px;
   border-radius: 16.8px;
 background: repeating-linear-gradient(135deg,#2f74fa 0 8.4px,rgba(47,116,250,0.75) 0 16.8px) left/0%   100% no-repeat,
         repeating-linear-gradient(135deg,rgba(47,116,250,0.2) 0 8.4px,rgba(47,116,250,0.1) 0 16.8px) left/100% 100%;
   animation: progress-p43u5e 2s infinite;
}

@keyframes progress-p43u5e {
   100% {
      background-size: 100% 100%;
   }
}
        `}
      </style>
    </div>
  );
};

export default Steppers;
