import React from "react";
import { Box, Modal } from "@mui/material";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { FiAward } from "react-icons/fi";
const NearbyBusinessesCard = (props) => {
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  const [viewEVoucher, setViewEVoucher] = React.useState(true);
  var user = JSON.parse(localStorage.getItem("userDetails"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "white",
    // border: '2px solid #000',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };
  const handleHome = (event) => {
    event.stopPropagation();
    navigate("/account");
    setModal(false);
  };
  return (
    <>
      <div
        className="p-3 flex items-center gap-3 rounded-4 manrope mb-3 shadow-[0_0_20px_1px_#0000000A]"
        // style={{ border: "1.5px solid rgba(0, 0, 0, 0.05)" }} box-shadow: 0px 0px 20px 1px #0000000A;
      >
        <img
          src={props?.img}
          className="rounded-4 object-cover w-16 h-16"
          alt="image not found"
        />
        <div className="flex-1">
          <h6 className="font-medium text-base mb-1">{props?.heading}</h6>
          <p className="text-xs fw-medium mb-0 text-gray">{props?.des}</p>
          <p className="text-xs fw-medium mb-0 text-gray">{props?.distance}</p>
        </div>
        {props?.campaignData?.reward && (
          <button
            className="rounded-5 font-14"
            style={{ height: 30 }}
            onClick={(event) => {
              event.stopPropagation();
              setModal(true);
            }}
          >
            <FiAward color="#DFEAF2" size={20} />
          </button>
        )}
      </div>

      <Modal
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="lato text-center ">
          {viewEVoucher ? (
            <div className="">
              <h4
                className="fw-bold mt-3 text-capitalize"
                style={{ color: "#2F3542" }}
              >
                {props?.campaignData?.reward?.description}{" "}
              </h4>
              <br />

              <div className="flex justify-center">
                <QRCode
                  className="my-5"
                  size={160}
                  value={JSON.stringify({
                    user_id: user?.id,
                    offer_id: props?.campaignData?.id,
                  })}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="text-center flex justify-center">
                <svg
                  fill="#3461FD"
                  width="85px"
                  height="85px"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>ionicons-v5-e</title>
                  <path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48ZM364.25,186.29l-134.4,160a16,16,0,0,1-12,5.71h-.27a16,16,0,0,1-11.89-5.3l-57.6-64a16,16,0,1,1,23.78-21.4l45.29,50.32L339.75,165.71a16,16,0,0,1,24.5,20.58Z" />
                </svg>
              </div>
              <h4 className="fw-bold mt-3" style={{ color: "#2F3542" }}>
                Congrats! <br />
                Your E-voucher is Ready!
              </h4>
              <p className="font-14 mt-3" style={{ color: "#2F3542" }}>
                Time to treat yourself! show this e-voucher at the store to
                redeem your reward.
              </p>
            </>
          )}
          <br />
          <div className="flex-column d-flex gap-3 w-75 mx-auto ">
            {!viewEVoucher && (
              <button
                className="helvetica-medium fw-medium  btn-fill-blue p-3 rounded-5 fw-semibold"
                type="button"
                onClick={(event) => {
                  event.stopPropagation();
                  setViewEVoucher(true);
                }}
              >
                View E-Voucher
              </button>
            )}
            <button
              className="helvetica-medium fw-medium  p-3 rounded-5 fw-semibold text-blue"
              type="button"
              style={{ background: "#CCD7FF" }}
              onClick={handleHome}
            >
              Go Home
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default NearbyBusinessesCard;
