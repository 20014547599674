import React from 'react'
import '../assets/css/versionThree.scss'
import img from '../assets/images/cafe.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { IoClose, IoSearch } from 'react-icons/io5';
import { MdOutlineMyLocation } from 'react-icons/md';
import { FiSearch } from 'react-icons/fi';
import NearbyBusinessesCard from '../components/NearbyBusinessesCard';
import useAOS from '../customHook/useAOS';
import { Box, Modal } from "@mui/material";
import QRCode from "react-qr-code";

const OfferDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { offer, businessData } = location?.state || "";
    var user = JSON.parse(localStorage.getItem("userDetails"));
    const [modal, setModal] = React.useState(false);
    const handleHome = () => {
        navigate("/account");
        setModal(false);
    };
    useAOS();
    const style = {
        position: "absolute",
        top: "60%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 350,
        bgcolor: "white",
        // border: '2px solid #000',
        borderRadius: "20px",
        p: 4,
    };
    const [viewEVoucher, setViewEVoucher] = React.useState(false);

    return (
        <>
            <div data-aos="fade-left" data-aos-duration="500" className='position-fixed w-100 top-0 bg-white z-3' style={{
                height: "59px",
                border: "1.5px solid #CACACA"
            }}>
                <div className=' p-3 '>
                    <p className='font-15 fw-semibold mb-0  flex items-center'><IoClose size={18} onClick={() => navigate(-1)} />&nbsp;{businessData?.business_name} - Offer Details</p>
                </div>


            </div>
            <section data-aos="fade-left" data-aos-duration="500" className=' z-1 position-relative offerRewards d-flex flex-column' style={{ height: "calc(100vh - 59px)", marginTop: "59px" }}>
                <div className='contianer-fluid p-0 pt-2 flex-grow-1 d-flex flex-column'>
                    <h5 className='fw-semibold text-center manrope mt-3'>{offer?.offer_heading}</h5>
                    <ul className=" row nav nav-pills " id="pills-tab" role="tablist">
                        <li className="nav-item col-6 p-0" role="presentation">
                            <button className="nav-link fw-medium manrope pb-3 pt-2 rounded-0 w-100 active bg-transparent font-14" id="pills-details-tab" data-bs-toggle="pill" data-bs-target="#pills-details" type="button" role="tab" aria-controls="pills-details" aria-selected="true">Details</button>
                        </li>
                        <li className="nav-item col-6 p-0" role="presentation">
                            <button className="nav-link rounded-0 manrope pb-3 pt-2 fw-medium w-100 bg-transparent font-14" id="pills-terms-tab" data-bs-toggle="pill" data-bs-target="#pills-terms" type="button" role="tab" aria-controls="pills-terms" aria-selected="false">Terms & Conditions</button>
                        </li>
                    </ul>
                    <div className="tab-content manrope p-3 pt-0 flex-grow-1" id="pills-tabContent">
                        <div className="tab-pane fade show h-100 active" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab" tabIndex={0}>
                            <div className='mt-3'>

                                <p className='font-14 text-black-50'>{offer?.offer_subheading}</p>
                                {
                                    offer?.reward_id &&
                                
                                    <Box sx={style} className="lato text-center flex justify-center items-center flex-column">

                                        <QRCode
                                            className="my-5"
                                            size={160}
                                            value={JSON.stringify({
                                                user_id: user?.id,
                                                reward_id: offer?.reward?.id,
                                            })}
                                        />

                                        <br />
                                        <div className="flex-column d-flex gap-3 w-75 mx-auto ">
                                            <button
                                                className="helvetica-medium fw-medium  p-3 rounded-5 fw-semibold text-blue"
                                                type="button"
                                                style={{ background: "#CCD7FF" }}
                                                onClick={handleHome}
                                            >
                                                Go Home
                                            </button>
                                        </div>
                                    </Box>
                                }
                            </div>
                            
                        </div>
                        <div className="tab-pane fade h-100  " id="pills-terms" role="tabpanel" aria-labelledby="pills-terms-tab" tabIndex={0}>
                            <div className='mt-3'>

                                <p className='font-14 text-black-50'>{offer?.nitty_gritty_stuff} </p>


                            </div>
                        </div>
                    </div>
                </div>



            </section>
        </>
    )
}

export default OfferDetail
